/* Colour Scheme
-------------------------------------------------- */

:root {
  --main: white;
  --main-cont: rgb(64, 64, 64);

  --accent-1: #565BA8;
  --accent-1-cont: white;
}

.accent-1 {
  color: var(--accent-1-cont);
  background-color: var(--accent-1);
}

/* General
-------------------------------------------------- */

/* Set fonts */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p, a, li, th, td, legend, label, input, textarea, button {
  font-family: 'Source Sans Pro', sans-serif;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Scope One', serif;
}
pre, code {
  font-family:
    'Source Code Pro',
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
  white-space: pre-wrap;
}

/* Patch Bootstrap CSS
-------------------------------------------------- */

/* From: https://stackoverflow.com/a/67875303 */
/* -------------------------------------------------- */
.btn-close::before {
  visibility: visible;
  content: '✕';
  font-size: 1.5rem;
}

.sr-only::before {
  visibility: hidden;
}

.btn-close {
  visibility: hidden;
  position: absolute;
  right: 10px;
  top: 10px;
}
/* -------------------------------------------------- */
